import { UIEnvironment } from "@condoit-apps/config/environment/types";
import { createContext, useContext } from "react";

export const EnvironmentContext = createContext<UIEnvironment | null>(null);

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (!context) {
    throw new Error("useEnvironment must be used within an EnvironmentProvider");
  }
  return context;
};

export const EnvironmentProvider = ({ children, env }: { children: React.ReactNode; env: UIEnvironment }) => {
  return <EnvironmentContext.Provider value={env}>{children}</EnvironmentContext.Provider>;
};
